import { injectGlobal } from 'emotion';
import DBOzoneXMed from '../../assets/fonts/DBOzoneX-Medium.woff';
import HelveticaNeueMedium from '../../assets/fonts/HelveticaNeue-Medium.woff';

injectGlobal`
  @font-face {
    font-family: 'DBOzoneXMed';
    src: url(${DBOzoneXMed}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'HelveticaNeueMedium';
    src: url(${HelveticaNeueMedium}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;