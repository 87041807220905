import fetcher from '~/app/lib/fetcher';

const endpoint = 'utm'

const utm = () => {
  return {
    /**
     * Create new rich menu
     *
     * @param {any} data
     * @param {any} params
     */
    create (data, params) {
      return fetcher.post(endpoint, data)
    }
  }
}

export default {};
export const UtmService = utm();