import config from '~/app/lib/config';


/**
 * Use liff hook
 *
 * @see {@link https://developers.line.biz/en/reference/liff/}
 */
function useLIFF () {
  const { liff } = window;
  const { LIFF_ID } = config;

  return {
    ...liff,
    /**
     * Initialise line liff application
     *
     * @function
     * @return {Promise<void>}
     */
    init () {
      return liff.init({ liffId: LIFF_ID });
    },
  };
}

export default useLIFF;
