import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';
import { Helmet } from "react-helmet";

import redirectLink from './redirect-link';
import themeConfig from './theme-config';

import config from '~/app/lib/config';
import { useLIFF } from '~/app/hooks';
import { UtmService } from '~/app/services/utm';

import './styles';


/**
 * App
 *
 * @returns {JSX.Element}
 */
function App () {

  const liff = useLIFF(config.LIFF_ID);

  const [ targetMenu, setTargetMenu ] = useState();
  const [ theme, setTheme ] = useState();
  const [ menu, setMenu ] = useState();
  const [ profile, setProfile ] = useState();

  const renderHelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href={_.get(theme, 'favicon')} type="image/x-icon" />
        <link rel="icon" href={_.get(theme, 'favicon')} type="image/x-icon" />
        <title>{_.get(theme, 'title')}</title>
      </Helmet>
    )
  }

  const getParameter = () => {
    const { pathname, search } = window.location;
    const mall = pathname.split('/')[1];
    const query = new URLSearchParams(search);
    const menu = pathname.split('/')[2] !== null ? pathname.split('/')[2] : query.get('menu');
    if (!mall) alert('Mall not found. Example localhost/terminal-21')
    if (!query) alert('Menu not found. Example localhost/terminal-21?menu=shop')
    return {
      mall,
      menu
    }
  }

  /**
   * Select theme
   *
   * @description select theme at the first render. there are 2 theme; terminal-21 and fashionisland.
   * @returns {void}
   */
  const selectTheme = () => {
    const { menu, mall } = getParameter()
    setMenu(menu)
    setTheme(themeConfig[mall]);
    setTargetMenu(redirectLink[mall][menu]);
  };

  /**
   * Redirect
   *
   * @param {'asok'|'korat'|'pattaya'|'fashion'|'promenade'} path
   * @returns {void}
   */
  const redirect = (path) => {
    window.location.replace(targetMenu[path]);
  }

  const handleRedirect = ({ path }) => {
    const { menu } = getParameter()
    UtmService.create({
      "source": 'portal_app',
      "medium": 'button',
      "campaign": menu,
      "content": path,
      "subject": profile.userId
    })
    redirect(path);
  }

  /**
   * Render menu
   *
   * @param {any} branches
   * @returns {JSX.Element}
   */
  const renderMenu = (menus) => {
    return _.map(menus, (menu) => (
      <HolderEach onClick={() => handleRedirect(menu)}>
        <TextContainer><text>{_.get(menu, 'title')}</text></TextContainer>
        <BtnHolder src={_.get(menu, 'src')} alt="" />
      </HolderEach>
    ));
  }

  useEffect(() => {
    const { menu, mall } = getParameter()
    const init = async () => {
      try {
        await liff.init();
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          setProfile(profile);
          UtmService.create({
            "source": "line",
            "medium": "richmenu",
            "campaign": "richmenu_tracking",
            "content": menu,
            "subject": profile.userId
          })
        } else {
          liff.login({ redirectUri: `${config.REDIRECT_URL}/${mall}/${menu}` });
          // liff.login({ redirectUri: `${config.REDIRECT_URL}/${mall}?menu=${menu}` });
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
    selectTheme();
  }, []);

  const renderMenuTitle = () => {
    switch (menu) {
      case 'shop':
        return 'Shopping'
      case 'event':
        return 'Event'
      case 'promotion':
        return 'Promotion'
      case 'service':
        return 'Service'
      case 'thaichana':
        return 'Check IN-OUT Thaichana'
      default:
        return ''
    }
  }

  return (
    <Styled bg={_.get(theme, 'bg')}>
      {renderHelmet()}
      <ContainerContent>
        <Header more={_.get(theme, 'moredel')} src={_.get(theme, 'header')} alt="" />
        <h1 style={{ margin: '10px 0', color: _.get(theme, 'headerColor', '#FFF') }}>{renderMenuTitle()}</h1>
        <ContainerContent>
          {renderMenu(_.get(theme, 'menu'))}
        </ContainerContent>
      </ContainerContent>
    </Styled>
  );
}

export default App;

App.propTypes = {}
App.defaultProps = {}

const Styled = styled.div`
  label: AppRouter;
  width: 100vw;
  height: 100%;
  color: #4c4c4c;
  background: url(${props => props.bg}) no-repeat top/cover;
`;

const ContainerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.img`
  height: auto;
  width: 70%;
  margin: 40px auto;
`;

const BtnHolder = styled.img`
  width: calc(100% - 40px);
  height: auto;
`;

const TextContainer = styled.div`
  width: 100%;
  margin: 0 0 5px 50px;
`;

const HolderEach = styled.div`
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
