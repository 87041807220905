export default {
  'terminal-21': {
    shop: {
      asok: 'https://www.terminal21.co.th/asok/shop/th/b',
      korat: 'https://www.terminal21.co.th/korat/lg-floor/',
      pattaya: 'https://www.terminal21.co.th/pattaya/gf-floor/',
    },
    event: {
      asok: 'https://www.terminal21.co.th/asok/event/th',
      korat: 'https://www.terminal21.co.th/korat/event-activity/',
      pattaya: 'https://www.terminal21.co.th/pattaya/event-activity/',
    },
    promotion: {
      asok: 'https://www.terminal21.co.th/asok/promotion/th',
      korat: 'https://www.terminal21.co.th/korat/promotion/',
      pattaya: 'https://www.terminal21.co.th/pattaya/promotion/',
    },
    service: {
      asok: 'https://www.terminal21.co.th/asok/customer_service/th',
      korat: 'https://www.terminal21.co.th/korat/customer-service/',
      pattaya: 'https://www.terminal21.co.th/pattaya/customer-service/',
    },
    thaichana: {
      asok: 'https://qr.thaichana.com/?appId=0001&shopId=S0000007857',
      korat: 'https://qr.thaichana.com/?appId=0001&shopId=S0000011218',
      pattaya: 'https://qr.thaichana.com/?appId=0001&shopId=S0000008306',
    },
  },
  fashionisland: {
    shop: {
      fashion: 'https://www.fashionisland.co.th/',
      promenade: 'http://www.thepromenade.co.th/directory/13/',
    },
    event: {
      fashion: 'https://www.fashionisland.co.th/event/events/',
      promenade: 'http://www.thepromenade.co.th/event/',
    },
    promotion: {
      fashion: 'https://www.fashionisland.co.th/promotions-privileges/',
      promenade: 'http://www.thepromenade.co.th/promotion/',
    },
    service: {
      fashion: 'https://www.fashionisland.co.th/customer/',
      promenade: 'http://www.thepromenade.co.th/home/',
    },
    thaichana: {
      fashion: 'https://qr.thaichana.com/?appId=0001&shopId=S0000005329',
      promenade: 'https://qr.thaichana.com/?appId=0001&shopId=S0000005837',
    },
  }
}
