import { injectGlobal } from 'emotion';

injectGlobal`
  h1, h2, h3, h4, h5, span, p, a, b, i, strong {
    line-height: 1;
    font-weight: normal;
    font-style: normal;
  }
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.75rem;
  }
  h5, p, a, b, i, strong {
    font-size: 0.65rem;
  }
`;

// 24px => 1.5rem
// 18px => 1.125rem
// 16px => 1rem
// 12px => 0.75rem