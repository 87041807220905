import { injectGlobal } from "emotion";
import styled from "@emotion/styled";
injectGlobal`
  html {
    font-size: 10px;
    font-weight: normal;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'HelveticaNeueMedium';
    color: white;
  }
  html, body, #root {
    height: 100%;
    overflow: hidden;
  }
  .switch-wrapper {
    position: relative;
    height: 100%;
  }
  .switch-wrapper > div {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  .switch-wrapper > div > div {

    /* overflow: hidden; */
  }

  h1,h2,h3,h4,h5,h6{
    color: white;
    margin: 0;
  }
  p{
    margin-bottom: 0;
  }
`;

export const ContentFullHeightScroll = styled.div`
  label: ContentFullHeightScroll;
  width: 100%;
  height: 100%;
  /* height: calc(100% - 70px); */
  overflow: scroll;
`;

export const Text = styled.p`
  font-family: ${props => props.fontFam};
  font-size: ${props => props.fontSize || "14"}px;
  color: ${props => props.color || "#000"};
  opacity: ${props => props.opacity || 1};
  line-height: ${props => props.lineHeight || 1};
`;
