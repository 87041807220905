/**
 * Get env
 *
 * @param {string} property
 * @return {string}
 */
function get (property) {
  return process.env[`REACT_APP_${property}`];
}

export default {
  NODE_ENV: process.env.NODE_ENV,
  STAT_SERVICE: get('STAT_SERVICE'),
  LIFF_ID: get('LIFF_ID'),
  REDIRECT_URL: get('REDIRECT_URL'),
};
